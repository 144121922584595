import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

// Components
import { RestartIcon, Typography, getColorByName } from '@modclub-app/uikit';

// Types
import { RefreshIndicatorPropTypes } from './types';

export const RefreshIndicator: React.FC<RefreshIndicatorPropTypes> = ({ 
  value, 
  onRefresh, 
  isSpinning
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (isSpinning) {
      controls.start({
        rotate: [0, 360],
        transition: {
          duration: 1,
          ease: 'linear',
          repeat: Infinity
        }
      });
    } else {
      controls.stop();
    }
  }, [isSpinning, controls]);

  const handleClick = async () =>  {
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <motion.div
        className="cursor-pointer"
        onClick={handleClick}
        animate={controls}
      >
        <RestartIcon 
          stroke={getColorByName('F00')?.hex} 
        />
      </motion.div>
      <Typography 
        size="sm"
        color='F00'
      >{value}</Typography>
    </div>
  );
};
