import React from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useProfile } from '../../../hooks/useProfile';

// Data
import { DATA_COMMON } from '../../../data/dataCommon';

export const useOnboarding = () => {
  const navigate = useNavigate();
  const { account } = useProfile();
  
  const { 
    title, 
    text, 
    pohContinueText, 
    reviewingText, 
    iconName 
  } = DATA_COMMON.onboarding;

  const handlerOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate("/poh");
  };

  const stepName = account?.currentOnboardingStep 
    ? Object.keys(account?.currentOnboardingStep)[0] : '';

  const alertText = stepName === 'poh_uniqueness' ? reviewingText : text;

  return {
    stepName,
    title,
    alertText,
    pohContinueText,
    iconName,
    handlerOnClick,
  };
};
