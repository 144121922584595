import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography } from '@modclub-app/uikit';
import { RefreshIndicator } from '../../../uikit';

import { RestartAmountPropTypes } from '../types';

export const RestartAmount: React.FC<RestartAmountPropTypes> = ({ 
  balance,
  onRefresh,
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center justify-between'>
      <Typography size="sm" color="F00">
        {t('app.components.smartkit.payment_modal.restart_amount.title')}
      </Typography>
      <RefreshIndicator 
        onRefresh={onRefresh}
        value={`${balance} ${t('app.components.smartkit.payment_modal.restart_amount.unit')}`}
      />
    </div>
  );
}