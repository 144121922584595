import {useToken} from './useToken';

export const createHeaders = (
    additionalHeaders: { [key: string]: string } = {}
): HeadersInit => {
    const headers: { [key: string]: string } = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...additionalHeaders,
    };

    Object.keys(headers).forEach(key => {
        if (headers[key] === undefined) {
            delete headers[key];
        }
    });

    return headers as HeadersInit;
};

export const createRequestOptions = (
    method: string,
    headers: HeadersInit,
    body: object
): RequestInit => ({
    method,
    headers,
    body: JSON.stringify(body),
});


export interface FetchResponse {
    success: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    error?: string;
}

export const handleResponse = async (
    response: Response
) : Promise<FetchResponse> => {
    if (!response.ok) {
        throw new Error(`ERROR! Responce status: ${response.status}`);
    }
    return response.json();
};

export interface FetchStandardProps {
    endpoint: string;
    method?: string;
    body?: object;
    additionalHeaders?: { [key: string]: string };
    authorize?: boolean;
}

export const useFetchStandard = () => {
    const {refreshToken, token} = useToken();

    return async (
        {
            endpoint,
            method = 'GET',
            body = {},
            additionalHeaders = {},
            authorize = false,
        }: FetchStandardProps) => {
        const maxAttempts = 2; // Allow one retry after token refresh

        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            try {
                if (authorize) {
                    additionalHeaders = {...additionalHeaders, Authorization: `Bearer ${token}`};
                }

                const headers = createHeaders(additionalHeaders);
                const requestOptions = createRequestOptions(method, headers, body);

                const response = await fetch(endpoint, requestOptions);

                if (authorize && response.status === 401 && attempt === 0) {
                    // If 401 Unauthorized and it's the first attempt, try to refresh the token
                    console.warn('Unauthorized. Attempting to refresh token...');

                    try {
                        await refreshToken(); // Refresh the token
                        continue; // Retry the request loop
                    } catch (tokenError) {
                        console.error('Token refresh failed:', tokenError);
                        throw new Error('Token refresh failed. Redirecting to login...');
                    }
                }

                return handleResponse(response);
            } catch (error) {
                if (attempt >= maxAttempts - 1) {
                    console.error('Fetch error:', error);
                    throw error; // If max attempts reached, throw the error
                }
            }
        }
    };
};
