import React from "react";

// Components
import { Typography, Button } from "@modclub-app/uikit";
import { MessagePlate } from '../MessagePlate';

// Hooks
import { useOnboarding } from '../customHooks/useOnboarding';
import { useUpdateProfileStatus } from '../customHooks/useUpdateProfileStatus';

export const Onboarding = () => {
  useUpdateProfileStatus();

  const {
    stepName,
    title,
    alertText,
    pohContinueText,
    iconName,
    handlerOnClick,
  } = useOnboarding();

  if (stepName === 'completed') {
    return null;
  }

  return (
    <MessagePlate
      title={title}
      alertText={alertText}
    >
      <React.Fragment>
        {(stepName == 'poh_liveness') && (
          <Button
            buttonText={pohContinueText}
            onClick={handlerOnClick}
            iconName={iconName}
            size="medium"
            skin="standard"
            theme="black"
          />
        )}

        {(stepName === 'poh_uniqueness') && (
          <Typography color="F00">
            {alertText}
          </Typography>
        )}
      </React.Fragment>
    </MessagePlate>
  );
};