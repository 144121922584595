import React from 'react';
import { motion, useAnimation } from 'framer-motion';

// Components
import { RestartIcon, Typography, getColorByName } from '@modclub-app/uikit';

// Types
import { RefreshIndicatorPropTypes } from './types';

export const RefreshIndicator: React.FC<RefreshIndicatorPropTypes> = ({ 
  value, 
  onRefresh 
}) => {
  const controls = useAnimation();

  const handleClick = () => {
    controls.start({ 
      rotate: 360, 
      transition: { 
        duration: 0.5, 
        ease: 'easeInOut' 
      } 
    });

    controls.set({ rotate: 0 });
    
    if (onRefresh) {
      onRefresh();
    }    
  };

  return (
    <div className="flex items-center space-x-2">
      <motion.div
        className="cursor-pointer"
        onClick={handleClick}
        animate={controls}
      >
        <RestartIcon 
          stroke={getColorByName('F00')?.hex} 
        />
      </motion.div>
      <Typography 
        size="sm"
        color='F00'
      >{value}</Typography>
    </div>
  );
};