import React from 'react';
import cn from 'classnames';

// Components
import { Typography } from '@modclub-app/uikit';

// Types
import { InfoItemPropTypes } from '../types';

export const CardStatus: React.FC<InfoItemPropTypes> = ({ 
  status 
}) => (
  <div className={cn('rounded-full px-2', {
    'bg-E50': status === 'Processing' || status === 'Onboarding',
    'bg-D60': status === 'Rejected' || status === 'Failed',
    'bg-B50': status === 'Verified',
  })}>
    <Typography 
      color="F00"
      size="base"
    >
      {status}
    </Typography>
  </div>
);