import { FaceLivenessResult } from '../types';
import {FetchResponse, FetchStandardProps} from '../../../hooks/useFetchStandard';

const fetchAnalysisResults = async (
  sessionId: string,
  callback: (data: FaceLivenessResult | null) => void,
  fetchStandard: (data: FetchStandardProps) => Promise<FetchResponse>
) => {
  const endpoint = process.env.AWS_RECOGNITION_API_URL || '';

  try {
    const responceData = await fetchStandard({
      endpoint: `${endpoint}get_liveness_session_results`,
      method: 'POST',
      body: { session_id: sessionId },
      authorize: true
    });

    callback(responceData);
  } catch (e) {
    console.error('ERROR:: -> ', e);
  }
};

export default fetchAnalysisResults;
