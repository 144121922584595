import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography } from '@modclub-app/uikit';
import { Logo } from '../../../uikit';

export const PictureDecor = () => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const setPlayBack = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.7;
    }
  };

  return (
    <div className='relative flex flex-col h-full overflow-hidden rounded-xl'>
      <div className='absolute top-10 left-1/2 -translate-x-1/2 z-10'> 
        <Logo theme="white" />
      </div>

      <video 
        autoPlay 
        muted 
        loop
        playsInline
        ref={videoRef}
        onCanPlay={() => setPlayBack()}
        className='absolute top-0 bottom-0 left-0 right-0 h-full max-w-none rounded-xl w-full object-cover'
      >
        <source 
          src="/assets/15439742-hd_1080_1920_30fps.mp4"
          type="video/mp4"
        />
      </video>

      <div className='absolute bottom-10 left-1/2 -translate-x-1/2'>
        <Typography 
          size="sm"
          weight='light'
          whitespaceNowrap
          color="C100"
        >
          {t('app.components.layout.auth_profile_layout.picture_decor.tagline')}
        </Typography>
      </div>
    </div>
  );
}