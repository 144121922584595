import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography } from '@modclub-app/uikit';
import { CopyableText } from '../../../uikit';

// Utils
import { truncateMiddle } from '../../../../utils/util';

// Types
import { DecideIDSectionPropTypes } from '../types';

export const DecideIDSection: React.FC<DecideIDSectionPropTypes> = ({ 
  walletAddress 
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-3 w-full md:w-1/2'>
      <div className='w-full pb-[100%] bg-C00 hidden md:inline-block' />
      <div className="flex flex-col items-center justify-center">
        <Typography size="lg" color="F00">
          {t('app.components.smartkit.payment_modal.decide_id_section.title')}
        </Typography>
        <CopyableText 
          textToCopy={truncateMiddle(walletAddress, 5)} 
          copyButton
        />
      </div>
    </div>
  );
}