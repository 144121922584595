import { useMemo } from 'react';
import { useProfile } from '../../../hooks/useProfile';
import { getAccountStatus } from "../../../utils/accout_util";

type InfoItemsList = {
  token?: string;
  status?: string;
}

export const useInfoItemsList = (): {
  infoItemsList: InfoItemsList,
} => {
  const { isLoading, profile, account, principal } = useProfile();

  const infoItemsList: InfoItemsList = useMemo<InfoItemsList>(() => {
    if (isLoading || !profile) return {};

    let items: InfoItemsList = {
      token: principal || '',
    };

    if (account) {
      const status = getAccountStatus(account.state, account.currentOnboardingStep);
      const accountState = account && Object.keys(account.state)[0];

      items = {
        ...items,
        status,
      };

      if (accountState && accountState === 'rejected') {
        items.status = 'Failed';
      }
    }

    return items;
  }, [isLoading, profile, account, principal]);

  return {
    infoItemsList
  };
};