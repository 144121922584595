import * as React from "react";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { SiweIdentityProvider } from "ic-use-siwe-identity";
import { wagmiConfig } from "../pages/Authorization/Ethereum/wagmi.config";
import { WagmiProvider } from "wagmi";


import { ic_siwe_provider_actor } from "../../app/actors_by_env";
import { ic_siwe_provider_types } from "../../app/canister_types";

const EthereumProvider: React.FC<{ 
  children: React.ReactNode 
}> = ({ children }) => {  
  return (
    <WagmiProvider config={wagmiConfig}>
        <RainbowKitProvider modalSize="compact">
          <SiweIdentityProvider<ic_siwe_provider_types._SERVICE>
            canisterId={ic_siwe_provider_actor.canisterId}
            idlFactory={ic_siwe_provider_actor.idlFactory}
          >
            {children}
          </SiweIdentityProvider>
        </RainbowKitProvider>
    </WagmiProvider>
  );
};

export default EthereumProvider;