import { http } from 'wagmi';
import { arbitrum, base, mainnet, optimism, polygon, zora } from "wagmi/chains";

import { getDefaultConfig } from '@rainbow-me/rainbowkit'

export const supportedChains = [
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
];

// TODO: https://www.rainbowkit.com/docs/custom-wallet-list

// TODO:  Every dApp that relies on WalletConnect (https://cloud.walletconnect.com/) now needs to obtain a projectId from WalletConnect Cloud. 
export const wagmiConfig = getDefaultConfig({
  appName: 'DecideId',
  projectId: '44816106d8926a66d4978218ac9523ee',
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(),
  },
})