import { useSiweIdentity } from "ic-use-siwe-identity";
import { useAccount, useDisconnect } from "wagmi";
import { useInternetIdentity } from '../contexts/InternetIdentityContext';
import { Identity } from '@dfinity/agent';
import { useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface UseIdentityReturn {
  principal: string | null;
  identity: Identity | null;
  isConnected: boolean;
  isInitializing: boolean;
  disconnect: () => Promise<void>;
  deviceId: string | null;
}

export function useIdentity(): UseIdentityReturn {
  const { isConnected: e_isConnected } = useAccount();
  const { identity: e_identity, clear: e_clear, isInitializing: e_isInitializing } = useSiweIdentity();
  const { identity: ii_identity, disconnect: ii_disconnect, isInitializing: ii_isInitializing, isConnected: ii_isConnected } = useInternetIdentity();
  
  const identity = e_identity || ii_identity;
  const principal = identity && identity.getPrincipal().toText();
  
  const {disconnect: e_disconnect} = useDisconnect();
  const [deviceId, setdeviceId] = useState<string | null>(null);

  const disconnect = async () => {
    await ii_disconnect();
    await e_clear();
    await e_disconnect();
  }

  useEffect(() => {
    const loadDeviceId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setdeviceId(result.visitorId);
    };

    loadDeviceId();
  }, []);

  return {
    identity,
    principal,
    isConnected: e_isConnected || ii_isConnected,
    isInitializing: e_isInitializing || ii_isInitializing,
    deviceId,
    disconnect: disconnect,
  };
}
