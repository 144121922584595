import React from "react";
import { useTranslation } from 'react-i18next';

// Hooks
import { HandleTransferPohFeeErrorFunction, useWallet } from '../../../hooks/useWallet';

// Components
import { Button } from '@modclub-app/uikit';
import { SuccessModal } from '../../../components/uikit';
import { PaymentModal } from '../../../components/smartKit';
import { useIdentity } from "../../../hooks/useIdentity";
import { useNavigate } from "react-router";

export const DepositModal = () => {
  const { principal } = useIdentity();

  
  const transferPohFeeError : HandleTransferPohFeeErrorFunction = (error: Error, _variables: "DCD" | "ICP", _context: unknown) => { // eslint-disable-line @typescript-eslint/no-unused-vars
    // TODO: MOD-1330 properly handle transferPohFeeError
    window.alert(error);
  };

  const { balance, refresh, transferPohFee, pohFee, transferPohFeeStatus } = useWallet(transferPohFeeError);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {transferPohFeeStatus == 'success' ? (
        <SuccessModal 
          title={t('app.pages.main.success_modal.title')}
          subTitle={t('app.pages.main.success_modal.sub_title')}
          iconName="CheckCircleIcon"
        >
          <div className="max-w-xs m-auto w-full">
            <Button
              buttonText={t('app.pages.main.success_modal.success_button')}
              onClick={() => {
                navigate("/poh");
              }}
              rounded="lg"
              iconName="ArrowRightIcon"
              size="large"
              skin="standard"
              fullWidth
              theme="black"
            />
          </div>
        </SuccessModal>
      ) : (
        <PaymentModal 
          balance={balance?.DCD}
          onRefresh={refresh}
          onClickProcessPayment={transferPohFee}
          isProcessingPayment={transferPohFeeStatus == 'pending'}
          walletAddress={principal}
          options={[pohFee]}
          title={t('app.pages.main.deposit_modal.title')}
          subTitle={t('app.pages.main.deposit_modal.sub_title')}
          alertText={t('app.pages.main.deposit_modal.alert_text')}
        />
      )}
    </React.Fragment>
  );
}