export const idlFactory = ({ IDL }) => {
  const List = IDL.Rec();
  const ENV = IDL.Record({
    'decideid' : IDL.Principal,
    'security' : IDL.Principal,
    'actual_env' : IDL.Text,
    'ledger' : IDL.Principal,
    'decideid_assets' : IDL.Principal,
    'decideid_siwe' : IDL.Principal,
  });
  const TokenGatedAction = IDL.Variant({ 'can_start_poh' : IDL.Null });
  const RejectReason = IDL.Variant({
    'failed_face_disguised_detected' : IDL.Null,
    'failed_duplicate' : IDL.Null,
    'failed_face_not_detected' : IDL.Null,
    'failed_age_policy' : IDL.Null,
  });
  const Timestamp = IDL.Int;
  const AccountState = IDL.Variant({
    'approved' : IDL.Null,
    'onboarding' : IDL.Null,
    'rejected' : IDL.Null,
  });
  const AccountType = IDL.Variant({
    'organic' : IDL.Null,
    'third_party' : IDL.Null,
  });
  const OnboardingStep = IDL.Variant({
    'completed' : IDL.Null,
    'poh_uniqueness' : IDL.Null,
    'poh_liveness' : IDL.Null,
    'profile' : IDL.Null,
  });
  const Account = IDL.Record({
    'id' : IDL.Text,
    'principal' : IDL.Principal,
    'rejectReason' : IDL.Opt(RejectReason),
    'approvedBy' : IDL.Opt(IDL.Principal),
    'createdAt' : Timestamp,
    'createdBy' : IDL.Principal,
    'updatedAt' : Timestamp,
    'state' : AccountState,
    'accType' : AccountType,
    'rejectedBy' : IDL.Opt(IDL.Principal),
    'currentOnboardingStep' : OnboardingStep,
  });
  const Profile = IDL.Record({
    'email' : IDL.Opt(IDL.Text),
    'lastName' : IDL.Text,
    'firstName' : IDL.Text,
  });
  const GetAccountResponse = IDL.Record({
    'acc' : Account,
    'profile' : Profile,
  });
  const Result_3 = IDL.Variant({ 'ok' : GetAccountResponse, 'err' : IDL.Text });
  const DecideID__2 = IDL.Text;
  const FeatureFlag = IDL.Record({
    'name' : IDL.Text,
    'createdAt' : Timestamp,
    'description' : IDL.Opt(IDL.Text),
    'isEnabled' : IDL.Bool,
    'updatedAt' : Timestamp,
    'updatedBy' : IDL.Principal,
  });
  const StatusRequest = IDL.Record({
    'memory_size' : IDL.Bool,
    'cycles' : IDL.Bool,
    'heap_memory_size' : IDL.Bool,
  });
  const MetricsGranularity = IDL.Variant({
    'hourly' : IDL.Null,
    'daily' : IDL.Null,
  });
  const GetMetricsParameters = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const MetricsRequest = IDL.Record({ 'parameters' : GetMetricsParameters });
  const GetLogMessagesFilter = IDL.Record({
    'analyzeCount' : IDL.Nat32,
    'messageRegex' : IDL.Opt(IDL.Text),
    'messageContains' : IDL.Opt(IDL.Text),
  });
  const Nanos = IDL.Nat64;
  const GetLogMessagesParameters = IDL.Record({
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
    'fromTimeNanos' : IDL.Opt(Nanos),
  });
  const GetLatestLogMessagesParameters = IDL.Record({
    'upToTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
  });
  const CanisterLogRequest = IDL.Variant({
    'getMessagesInfo' : IDL.Null,
    'getMessages' : GetLogMessagesParameters,
    'getLatestMessages' : GetLatestLogMessagesParameters,
  });
  const GetInformationRequest = IDL.Record({
    'status' : IDL.Opt(StatusRequest),
    'metrics' : IDL.Opt(MetricsRequest),
    'logs' : IDL.Opt(CanisterLogRequest),
    'version' : IDL.Bool,
  });
  const StatusResponse = IDL.Record({
    'memory_size' : IDL.Opt(IDL.Nat64),
    'cycles' : IDL.Opt(IDL.Nat64),
    'heap_memory_size' : IDL.Opt(IDL.Nat64),
  });
  const UpdateCallsAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterHeapMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterCyclesAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const HourlyMetricsData = IDL.Record({
    'updateCalls' : UpdateCallsAggregatedData,
    'canisterHeapMemorySize' : CanisterHeapMemoryAggregatedData,
    'canisterCycles' : CanisterCyclesAggregatedData,
    'canisterMemorySize' : CanisterMemoryAggregatedData,
    'timeMillis' : IDL.Int,
  });
  const NumericEntity = IDL.Record({
    'avg' : IDL.Nat64,
    'max' : IDL.Nat64,
    'min' : IDL.Nat64,
    'first' : IDL.Nat64,
    'last' : IDL.Nat64,
  });
  const DailyMetricsData = IDL.Record({
    'updateCalls' : IDL.Nat64,
    'canisterHeapMemorySize' : NumericEntity,
    'canisterCycles' : NumericEntity,
    'canisterMemorySize' : NumericEntity,
    'timeMillis' : IDL.Int,
  });
  const CanisterMetricsData = IDL.Variant({
    'hourly' : IDL.Vec(HourlyMetricsData),
    'daily' : IDL.Vec(DailyMetricsData),
  });
  const CanisterMetrics = IDL.Record({ 'data' : CanisterMetricsData });
  const MetricsResponse = IDL.Record({ 'metrics' : IDL.Opt(CanisterMetrics) });
  const CanisterLogFeature = IDL.Variant({
    'filterMessageByContains' : IDL.Null,
    'filterMessageByRegex' : IDL.Null,
  });
  const CanisterLogMessagesInfo = IDL.Record({
    'features' : IDL.Vec(IDL.Opt(CanisterLogFeature)),
    'lastTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'firstTimeNanos' : IDL.Opt(Nanos),
  });
  const LogMessagesData = IDL.Record({
    'timeNanos' : Nanos,
    'message' : IDL.Text,
  });
  const CanisterLogMessages = IDL.Record({
    'data' : IDL.Vec(LogMessagesData),
    'lastAnalyzedMessageTimeNanos' : IDL.Opt(Nanos),
  });
  const CanisterLogResponse = IDL.Variant({
    'messagesInfo' : CanisterLogMessagesInfo,
    'messages' : CanisterLogMessages,
  });
  const GetInformationResponse = IDL.Record({
    'status' : IDL.Opt(StatusResponse),
    'metrics' : IDL.Opt(MetricsResponse),
    'logs' : IDL.Opt(CanisterLogResponse),
    'version' : IDL.Opt(IDL.Nat),
  });
  const Result = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const SubWalletType = IDL.Text;
  const Subaccount = IDL.Vec(IDL.Nat8);
  const UserEventType = IDL.Variant({
    'fee_transfer' : IDL.Null,
    'reverification' : IDL.Null,
    'acc_creation' : IDL.Null,
    'retry' : IDL.Null,
  });
  const UserEvent = IDL.Record({
    'decideid' : IDL.Text,
    'principal' : IDL.Principal,
    'note' : IDL.Opt(IDL.Text),
    'createdAt' : Timestamp,
    'eventType' : UserEventType,
  });
  List.fill(IDL.Opt(IDL.Tuple(UserEvent, List)));
  const Secret = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const Event = IDL.Record({ 'topic' : IDL.Text, 'payload' : IDL.Principal });
  const ConsumerPayload = IDL.Variant({
    'secrets' : IDL.Vec(Secret),
    'events' : IDL.Vec(Event),
    'admins' : IDL.Vec(IDL.Principal),
  });
  const Header = IDL.Tuple(IDL.Text, IDL.Text);
  const Request = IDL.Record({
    'url' : IDL.Text,
    'method' : IDL.Text,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(Header),
    'certificate_version' : IDL.Opt(IDL.Nat16),
  });
  const StreamingToken = IDL.Vec(IDL.Nat8);
  const StreamingCallbackResponse = IDL.Record({
    'token' : IDL.Opt(StreamingToken),
    'body' : IDL.Vec(IDL.Nat8),
  });
  const StreamingCallback = IDL.Func(
      [StreamingToken],
      [IDL.Opt(StreamingCallbackResponse)],
      ['query'],
    );
  const StreamingStrategy = IDL.Variant({
    'Callback' : IDL.Record({
      'token' : StreamingToken,
      'callback' : StreamingCallback,
    }),
  });
  const Response = IDL.Record({
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(Header),
    'upgrade' : IDL.Opt(IDL.Bool),
    'streaming_strategy' : IDL.Opt(StreamingStrategy),
    'status_code' : IDL.Nat16,
  });
  const FeatureFlagName = IDL.Variant({
    'JWT_CREATION_ENABLED' : IDL.Null,
    'PAYMENT_ENABLED' : IDL.Null,
  });
  const DecideID__1 = IDL.Text;
  const RegisterAccountResponse = IDL.Record({
    'token' : IDL.Opt(IDL.Text),
    'decide_id' : DecideID__1,
  });
  const Result_2 = IDL.Variant({
    'ok' : RegisterAccountResponse,
    'err' : IDL.Text,
  });
  const Result_1 = IDL.Variant({ 'ok' : FeatureFlag, 'err' : IDL.Text });
  const CollectMetricsRequestType = IDL.Variant({
    'force' : IDL.Null,
    'normal' : IDL.Null,
  });
  const UpdateInformationRequest = IDL.Record({
    'metrics' : IDL.Opt(CollectMetricsRequestType),
  });
  const DecideID = IDL.Service({
    'checkTokenGate' : IDL.Func([TokenGatedAction], [IDL.Bool], []),
    'getAccByCaller' : IDL.Func([], [Result_3], []),
    'getAccount' : IDL.Func([DecideID__2], [Result_3], []),
    'getAllFeatureFlags' : IDL.Func([], [IDL.Vec(FeatureFlag)], ['query']),
    'getCanistergeekInformation' : IDL.Func(
        [GetInformationRequest],
        [GetInformationResponse],
        ['query'],
      ),
    'getPublicKey' : IDL.Func([], [Result], []),
    'getSubWalletBlobByCaller' : IDL.Func(
        [SubWalletType],
        [IDL.Opt(Subaccount)],
        [],
      ),
    'getToken' : IDL.Func([], [Result], []),
    'getUserEvent' : IDL.Func([], [IDL.Opt(List)], []),
    'getVerifiedButNotApproved' : IDL.Func([], [IDL.Vec(IDL.Principal)], []),
    'handleSubscription' : IDL.Func([ConsumerPayload], [], []),
    'hello' : IDL.Func([], [IDL.Text], ['query']),
    'http_request' : IDL.Func([Request], [Response], ['query']),
    'http_request_update' : IDL.Func([Request], [Response], []),
    'isFeatureEnabled' : IDL.Func([FeatureFlagName], [IDL.Bool], ['query']),
    'refillVC' : IDL.Func([], [IDL.Nat32], []),
    'registerAccount' : IDL.Func(
        [IDL.Opt(IDL.Text), IDL.Opt(IDL.Text), IDL.Opt(IDL.Text)],
        [Result_2],
        [],
      ),
    'setApiKey' : IDL.Func([IDL.Text], [], ['oneway']),
    'setFeatureFlag' : IDL.Func(
        [FeatureFlagName, IDL.Bool, IDL.Opt(IDL.Text)],
        [Result_1],
        [],
      ),
    'updateCanistergeekInformation' : IDL.Func(
        [UpdateInformationRequest],
        [],
        [],
      ),
    'updateProfile' : IDL.Func(
        [IDL.Opt(IDL.Text), IDL.Opt(IDL.Text), IDL.Opt(IDL.Text)],
        [Result],
        [],
      ),
  });
  return DecideID;
};
export const init = ({ IDL }) => {
  const ENV = IDL.Record({
    'decideid' : IDL.Principal,
    'security' : IDL.Principal,
    'actual_env' : IDL.Text,
    'ledger' : IDL.Principal,
    'decideid_assets' : IDL.Principal,
    'decideid_siwe' : IDL.Principal,
  });
  return [ENV];
};
