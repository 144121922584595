import { useState, useEffect } from "react";

import { fetchStandard } from "../../../utils/fetchStandardApi";

export const useGetUserCounterData = () => {
  const [userCounter, setUserCounter] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStandard({
          endpoint: "https://rlz47-aqaaa-aaaah-qdcra-cai.raw.icp0.io/config",
        });
        setUserCounter(data.verified_users_count);
      } catch (error) {
        console.error("Fetch error:", error);
        throw error;
      }
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, 30000);

    fetchData();

    return () => clearInterval(intervalId);
  }, []);

  return { userCounter };
};
