import React from 'react';

// Components
import { SelectableCard } from '../../../uikit';

// Types
import { OptionsPropTypes } from '../types';

export const PaymentOptions: React.FC<OptionsPropTypes> = ({ options }) => (
  <div className='flex flex-col gap-3'>
    {options.map((item, index) => (
      <SelectableCard
        id={index}
        key={index}
        label={`Option ${index+1}`}
        text={`${item.DCD}`}
        isActive={true}
        onClick={(id) => console.log('Card ID -> ', id)}
      />
    ))}
  </div>
)