import React from "react";
import { motion } from "framer-motion";

// Components
import { Typography } from "@modclub-app/uikit";

// Hooks
import { useTranslation } from "react-i18next";
import { useGetUserCounterData } from "../customHooks/useGetUserCounterData";

// Utils
import { formatNumberWithSpaces } from "../../../utils/util";

export const UserCounter: React.FC<{
  counter: number;
}> = () => {
  const { userCounter } = useGetUserCounterData();
  const { t } = useTranslation();

  if (!userCounter) {
    return null;
  }

  return (
    <motion.div
      className="flex flex-col items-center justify-center p-5"
      variants={{
        hidden: { opacity: 0, y: 25 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={"visible"}
      transition={{ duration: 0.9, delay: 1.2 }}
    >
      <Typography color="C100" size="5xl" weight="medium" adaptive>
        {formatNumberWithSpaces(userCounter)}
      </Typography>
      <div className="flex items-center gap-2 opacity-60">
        <div className="w-9 h-9">
          <img className="w-full" src="assets/users-three-thin.svg" alt="" />
        </div>
        <Typography weight="light" size="base" color="C100">
          {t("app.pages.landing.user_counter.counter_text")}
        </Typography>
      </div>
    </motion.div>
  );
};
