import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { decidedid_actor } from "../actors_by_env";

interface ConfigMap {
  poh_verification_fee: number;
}

export function useConfig() {
  const network = process.env.DFX_NETWORK || "local";
  const decideidCanisterId = decidedid_actor.canisterId;
  let configEndpoint = '';
  
  if (network === "local") {
    configEndpoint = `http://${decideidCanisterId}.localhost:8000/config`;
  } else {
    configEndpoint = `https://${decideidCanisterId}.raw.icp0.io/config`;
  }

  const queryClient = useQueryClient();

  const fetchConfig = async (): Promise<ConfigMap> => {
    const response = await fetch(configEndpoint);
    if (!response.ok) {
      throw new Error("Failed to fetch config");
    }
    return response.json();
  };

  const {
    data: config,
    error,
    isError,
    isLoading,
    isSuccess,
  }: UseQueryResult<ConfigMap, Error> = useQuery({
    queryKey: ["Config"],
    queryFn: fetchConfig,
    retry: false,
  });

  // Note: This refreshConfig() function should only be called at the start of the app. 
  // If needed elsewhere, consider moving the config variable to a different API.
  const refreshConfig = async () => {
    await queryClient.invalidateQueries({ queryKey: ["Config"] });
  };

  return {
    config,
    isLoading,
    isSuccess,
    isError,
    error,
    refreshConfig,
  };
}
  