import React from 'react';

// Components
import { FeedbackAlert } from '@modclub-app/uikit';
import { TextSection } from '../../uikit/TextSection';
import { PaymentForm } from './PaymentForm';
import { DecideIDSection } from './DecideIDSection';

// Types
import { PaymentModalPropTypes } from './types';

export const PaymentModal: React.FC<PaymentModalPropTypes> = ({
  balance,
  onRefresh,
  onClickProcessPayment,
  isProcessingPayment,
  walletAddress,
  options,
  title,
  subTitle,
  alertText
}) => (
  <div className='flex flex-col gap-10'>
    {title && (
      <TextSection
        title={title}
        text={subTitle}
        textCenter
      />
    )}

    <div className='flex flex-col md:flex-row gap-4'>
      <PaymentForm 
        balance={balance} 
        onRefresh={onRefresh}
        options={options}
        onClickProcessPayment={onClickProcessPayment}
        isProcessingPayment={isProcessingPayment}
      />
      <DecideIDSection 
        walletAddress={walletAddress}
      />
    </div>

    {alertText && (
      <FeedbackAlert
        skin="yellow"
        text={alertText}
      />
    )}
  </div>
);